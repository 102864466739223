"use client";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { AlertCircle, Loader2 } from "lucide-react";
import { Turnstile } from "@marsidev/react-turnstile";
import { useCallback } from "react";

import { env } from "@/env.mjs";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { signInFormSchema, type SignInFormSchema } from "@/schemas/auth-forms";
import { login } from "@/auth/client";

export const SignInForm = ({
  onSignInSuccess,
}: {
  onSignInSuccess?: () => void;
}) => {
  const signInform = useForm<SignInFormSchema>({
    resolver: zodResolver(signInFormSchema),
  });

  const onSignInSubmit = useCallback(
    async (values: SignInFormSchema) => {
      try {
        await login(values.username, values.password, values.token);
        onSignInSuccess?.();
      } catch (error) {
        if (error instanceof Error) {
          signInform.setError("root", {
            type: "server",
            message: error.message,
          });
        } else {
          signInform.setError("root", {
            type: "server",
            message: "Some error occurred. Please try to login again.",
          });
        }
      }
    },
    [signInform, onSignInSuccess],
  );
  return (
    <Form {...signInform}>
      <form
        onSubmit={signInform.handleSubmit(onSignInSubmit)}
        className="space-y-5"
      >
        <FormField
          control={signInform.control}
          name="username"
          key={"username"}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>E-mail</FormLabel>
              <FormControl>
                <Input
                  type="email"
                  placeholder="Enter Your e-mail"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={signInform.control}
          name="password"
          key={"password"}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Link
            className="text-sm text-ring lg:text-sm"
            href="/auth/forgot-password"
          >
            Forgot Password?
          </Link>
        </div>
        <FormField
          control={signInform.control}
          name="token"
          key={"token"}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Turnstile
                  className="mx-auto"
                  onError={() => {
                    signInform.setError("root", {
                      type: "server",
                      message:
                        "Error occurred while verifying you are not a robot. Please try again.",
                    });
                  }}
                  onSuccess={(token) => {
                    signInform.setValue("token", token);
                  }}
                  siteKey={env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full"
          type="submit"
          disabled={signInform.formState.isSubmitting}
        >
          {signInform.formState.isSubmitting && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Log in
        </Button>
        {signInform.formState.errors.root?.message && (
          <Alert
            variant="destructive"
            id="root-error-message"
            className="mx-auto"
          >
            <AlertCircle className="mr-2 h-4 w-4" />
            <AlertTitle>{signInform.formState.errors.root?.message}</AlertTitle>
          </Alert>
        )}
      </form>
    </Form>
  );
};
